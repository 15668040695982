/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function TransparentFooter() {
  return (
    <footer className='footer'>
      <Container>
        <div className=' text-center' id='copyright'>
          © 2024, WandInfoTech
        </div>
      </Container>
    </footer>
  );
}

export default TransparentFooter;
