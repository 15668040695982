import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

// core components

function Download() {
  return (
    <>
      <div
        className='section section-download'
        data-background-color='black'
        id='download-section'>
        <Container>
          <Row className='justify-content-md-center sharing-area text-center'>
            <Col className='text-center' lg='8' md='12'>
              <h3>Contact Us</h3>
            </Col>
            <Col className='text-center' lg='8' md='12'>
              Email:{" "}
              <a href='mailto:wandinfotech@gmail.com'>wandinfotech@gmail.com</a>
              <br />
              Phone: <a href='tel:+917358512683'>+91 7358512683</a>
            </Col>
            {/* <Col className='text-center' lg='8' md='12'>
              <Button
                className='btn-neutral btn-icon btn-round'
                color='twitter'
                href='https://www.twitter.com/'
                id='tooltip86114138'
                size='lg'
                target='_blank'>
                <i className='fab fa-twitter'></i>
              </Button>
              <UncontrolledTooltip delay={0} target='tooltip86114138'>
                Follow us
              </UncontrolledTooltip>
              <Button
                className='btn-neutral btn-icon btn-round'
                color='facebook'
                href='https://www.facebook.com/'
                id='tooltip735272548'
                size='lg'
                target='_blank'>
                <i className='fab fa-facebook-square'></i>
              </Button>
              <UncontrolledTooltip delay={0} target='tooltip735272548'>
                Like us
              </UncontrolledTooltip>
              <Button
                className='btn-neutral btn-icon btn-round'
                color='Whatsapp'
                href='https://wa.me/+919941272071'
                id='tooltip647117716'
                size='lg'
                target='_blank'>
                <i className='fab fa-whatsapp'></i>
              </Button>
              <UncontrolledTooltip delay={0} target='tooltip647117716'>
                Contact us
              </UncontrolledTooltip>
              <Button
                className='btn-neutral btn-icon btn-round'
                color='github'
                href='https://github.com/'
                id='tooltip331904895'
                size='lg'
                target='_blank'>
                <i className='fab fa-github'></i>
              </Button>
              <UncontrolledTooltip delay={0} target='tooltip331904895'>
                Star on Github
              </UncontrolledTooltip>
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Download;
