/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function DarkFooter() {
  return (
    <footer className='footer ' data-background-color='black'>
      <Container>
        <div className=' text-center' id='copyright'>
          © 2024, WandInfoTech .
        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;
